import React from 'react';
import classNames from 'classnames';
import t from '../utils/translate';
import SEO from '../components/seo';
import Layout from '../components/Layout/Layout';
import styles from '../style/four-oh-four.module.scss';
import LocaleLink from '../components/LocaleLink/LocaleLink';

const FourOhFour = () => {
  return (
    <Layout>
      <SEO title={`404 | SeeSalmon`} />
      <div className={styles.outer}>
        <div className={styles.inner}>
          <div>
            <LocaleLink to="/" className={styles.link}>
              {t('backToFront')}
            </LocaleLink>
          </div>
          <h1 className={classNames(styles.headline, 'headline-80')}>
            {t('fourOhFour')}
          </h1>
          <p className="headline-30">{t('noPage')}</p>
        </div>
      </div>
    </Layout>
  );
};

export default FourOhFour;
